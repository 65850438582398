<template>
    <table class="table table-bordered">
        <thead>
        <tr>
            <th>Particulars</th>
            <th v-if="hasItemDetails">Qty</th>
            <th v-if="hasItemDetails">Rate</th>
            <th>Amount</th>
            <th>VAT Rate</th>
            <th>VAT Amount</th>
            <th>Total Amount</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(item, index) in tableItems">
            <td>
                <p>{{hasItemDetails ? item.product.name : item.head.name}}</p>
                <span class="text-muted">{{item.description}}</span>
            </td>
            <td class="text-right" v-if="hasItemDetails">{{formatCurrency(item.quantity)}}</td>
            <td class="text-right" v-if="hasItemDetails">{{formatCurrency(item.rate)}}</td>
            <td class="text-right">{{formatCurrency(item.amount)}}</td>
            <td class="text-right">{{formatCurrency(item.vat)}}%</td>
            <td class="text-right">{{formatCurrency(item.vat_amount)}}</td>
            <td class="text-right">{{formatCurrency(item.total_amount)}}</td>
        </tr>
        </tbody>
    </table>
</template>

<script>
    import usePurchase from "@/services/modules/purchase";
    export default {
        props: {
            tableItems: {
                type: Object,
                required: true
            },
            hasItemDetails: {
                type: Boolean,
                required: true
            }
        },
        setup() {
            const {formatCurrency} = usePurchase();

            return {
                formatCurrency
            }
        }
    }
</script>